import { AppLayout } from 'modules/layout/AppLayout'
import { SettingsTabs } from './tabs'

export const Settings = () => {
  return (
    <AppLayout heading="Settings">
      <SettingsTabs />
    </AppLayout>
  )
}
