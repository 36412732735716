import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState } from 'react';
// import GeneralPanel from './GeneralPanel';
import MobileAppPanel from './MobileAppPanel';
// import WebAppPanel from './WebAppPanel';
import { Button, CircularProgress } from '@mui/material';
import { useEffect } from 'react';

import { map } from 'lodash';
import { socketClient } from 'client/socket.client';
import { useContext } from 'react';
import { SnackbarContext } from 'components/snackbar/SnackbarContext';

export const SettingsTabs = props => {
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState(0);
  const [updated, setUpdated] = useState([])
  const {setSeverity, showToast} = useContext(SnackbarContext)
  
  const getSettings = () => {
    socketClient.service('settings').find().then((result) => {
      setList(result)
      setLoading(false)
    }).catch((e) => {
      console.log(e)
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    getSettings()
  }, [tab])
  

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const renderTabPanel = () => {
    {/* TODO: uncomment code for additional settings */}
    // if (tab === 1) {
    //   return <WebAppPanel />
    // } else if (tab === 2) {
      return <MobileAppPanel list={list} updated={updated} setUpdated={setUpdated} />
    // } else {
    //   return <GeneralPanel />
    // }
  }

  const handleSave = async (e) => {
    if (!updated?.length) return

    try {
      const requests = map(updated, i => {
				const {_id, key, value, group} = i
				if (_id) {
					return socketClient.service('settings').patch(_id, {key, value, group})
				}
				return socketClient.service('settings').create({key, value, group})
			})
			await Promise.all(requests);
      getSettings()
      setSeverity('success')
      showToast('Settings updated successfully!')
      setUpdated([])
    } catch (error) {
      console.log(error)
      setSeverity('error')
      showToast('Failed to update Settings!')
    }

  }
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} display="flex" justifyContent="space-between">
        <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
          {/* TODO: uncomment code for additional settings */}
          {/* <Tab label="General"  />
          <Tab label="Web App"  /> */}
          <Tab label="Mobile App"  />
        </Tabs>
        <Button 
          variant='contained' 
          color='primary' 
          size='small' style={{height : '40px'}} 
          onClick={handleSave}
          disabled={!!!updated.length}
        > save </Button>
      </Box>
      {loading ? <CircularProgress /> : renderTabPanel()}
    </Box>
  )
}