import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import {Edit} from '@mui/icons-material'
import {useState} from 'react'
import * as yup from 'yup'
import {useFormik} from 'formik'
import PropTypes from 'prop-types'

const validationSchema = yup.object({
  username: yup.string().required('Username is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().matches(/^\+[0-9]+$/, 'Invalid Phone number. Phone number must be start with country code. eg +1' ),
  roles: yup.array().min(1, 'Select at least one role').required('Roles are required')
})

const rolesOptions = [
  {
    label: 'User',
    role: 'user'
  },
  {
    label: 'Admin',
    role: 'admin'
  },
  {
    label: 'Vendor',
    role: 'vendor'
  },
  {
    label: 'Finder',
    role: 'finder'
  },
  {
    label: 'Staff',
    role: 'staff'
  }
]
export const EditDetail = props => {
  const {data, setData, id, patch} = props
  const [enableEdit, setEnableEdit] = useState(true)

  const handleSave = async values => {
    if (values?.externalShelterId === '' || values?.externalShelterId === null) {
      delete values.externalShelterId
    }

    const result = await patch(id, values)

    if (result) setData(result)
  }

  const formik = useFormik({
    initialValues: {
      username: data?.username,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phone,
      roles: data?.roles ? data.roles : []
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      handleSave(values)
    }
  })

  return (
    <Grid item md={4} xs={12} p={3} sx={{boxShadow: 1}}>
      <Grid container direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" component="h6" sx={{fontSize: '16px', fontWeight: '600', color: '#646464'}}>
          Edit
        </Typography>
        <IconButton onClick={() => setEnableEdit(!enableEdit)}>
          <Edit />
        </IconButton>
      </Grid>
      <Box pt={2}>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            label="Username"
            name="username"
            value={formik.values.username || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
            margin="normal"
            disabled={enableEdit}
            fullWidth
          />
          <TextField
            label="First Name"
            name="firstName"
            value={formik.values.firstName || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            margin="normal"
            disabled={enableEdit}
            fullWidth
          />

          <TextField
            label="Last Name"
            name="lastName"
            value={formik.values.lastName || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            margin="normal"
            disabled={enableEdit}
            fullWidth
          />

          <TextField
            label="Email"
            name="email"
            value={formik.values.email || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            margin="normal"
            disabled={enableEdit}
            fullWidth
          />
          <TextField
            label="Phone"
            name="phone"
            value={formik.values.phone || ''}
            onInput={event => {
              event.target.value = event.target.value.replace(/^-/, '')
            }}
            onChange={(e) => {
              formik.setFieldValue('phone', e.target.value.replace(/[^0-9+]/g, ''))
            }}
            inputProps={{ inputMode: 'numeric' }}
            onBlur={formik.handleBlur}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            margin="normal"
            disabled={enableEdit}
            fullWidth
          />
          <FormControl fullWidth margin="normal" error={formik.touched.roles && Boolean(formik.errors.roles)}>
            <InputLabel>Roles</InputLabel>
            <Select
              name="roles"
              label="Roles"
              multiple
              value={formik.values.roles || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.roles && Boolean(formik.errors.roles)}
              helperText={formik.touched.roles && formik.errors.roles}
              inputProps={{'aria-label': 'roles'}}
              disabled={enableEdit}
            >
              {rolesOptions.map(item => (
                <MenuItem key={item.role} value={item.role}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="!mx-1"
            disabled={enableEdit}
            fullWidth
          >
            Save
          </Button>
        </form>
      </Box>
      <Box sx={{width: '100%'}}>
        <Divider />
      </Box>
      <Box sx={{py: {xs: 2, lg: 4, md: 2}, px: {xs: 1, lg: 5, md: 2}}}>
        <Typography variant="h6" component="h6" sx={{fontSize: '13px', fontWeight: '500'}}>
          Last Session:
        </Typography>
        <Typography variant="h6" component="h6" sx={{fontSize: '16px', fontWeight: '600'}}>
          OS iOS Safari , iphone 12
        </Typography>
        <Typography variant="h6" component="h6" sx={{fontSize: '13px', fontWeight: '600'}}>
          Tuesday, March 28 2023
        </Typography>
      </Box>
    </Grid>
  )
}

EditDetail.prototype = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  patch: PropTypes.func,
  id: PropTypes.string.isRequired
}
