import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import {FaUser} from 'react-icons/fa'
import {Grid, ListSubheader} from '@mui/material'
import Drawer from '@mui/material/Drawer'
import LogoImage from '../../src/images/app-logo.png'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {styled} from '@mui/material/styles'
import Collapse from '@mui/material/Collapse'
import AodIcon from '@mui/icons-material/Aod'
import FoodBankIcon from '@mui/icons-material/FoodBank'
import ListAltIcon from '@mui/icons-material/ListAlt'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import NightShelterIcon from '@mui/icons-material/NightShelter'
import LanIcon from '@mui/icons-material/Lan'
import StorefrontIcon from '@mui/icons-material/Storefront'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import GradingIcon from '@mui/icons-material/Grading'
import SellIcon from '@mui/icons-material/Sell'
import {
  SpaceDashboard,
  Inventory,
  BrandingWatermark,
  ShoppingBasket,
  Store,
  ExpandMore,
  Settings
} from '@mui/icons-material'
import ReviewsIcon from '@mui/icons-material/Reviews'
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel'

const Sidebar = props => {
  const {open} = props

  const [activeMenuItem, setActiveMenuItem] = useState('')

  const drawerWidth = 240
  const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  }))

  const onExpand = item => {
    setActiveMenuItem(activeMenuItem === item ? '' : item)
  }

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Grid container alignItems="center">
            <img src={LogoImage} alt="Logo" />
          </Grid>
        </DrawerHeader>
        <Divider />
        <List>
          <ListSubheader sx={{fontWeight: 'bold', color: 'black', fontSize: '14px', textAlign: 'left'}}>
            Connect, Rescue, care
          </ListSubheader>
          <List sx={{padding: '0'}}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => onExpand('user')}>
                <ListItemIcon>
                  <FaUser />
                </ListItemIcon>
                <ListItemText>Users </ListItemText>
                <ExpandMore />
              </ListItemButton>
            </ListItem>
            <Collapse in={activeMenuItem === 'user'} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>
                    <Link to="/">User List</Link>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>
                    <Link to="/invitations">Invite User</Link>
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>
          </List>
          <List sx={{padding: '0'}}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => onExpand('pets')}>
                <ListItemIcon>
                  <FormatListBulletedIcon />
                </ListItemIcon>
                <ListItemText>Pets</ListItemText>
                <ExpandMore />
              </ListItemButton>
            </ListItem>
            <Collapse in={activeMenuItem === 'pets'} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>
                    <Link to="/pets/dashboard">Dashboard</Link>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>
                    <Link to="/pets/list">Pet List</Link>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>
                    <Link to="/pets/types">Pet Type</Link>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>
                    <Link to="/pets/sub-types">Pet Sub Type</Link>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>
                    <Link to="/pets/activities">Activities</Link>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>
                    <Link to="/pets/sensitivities">Sensitivities</Link>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>
                    <Link to="/pets/wellness-types">Wellness Types</Link>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>
                    <Link to="/pets/vaccine-types">Vaccine Types</Link>
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>
          </List>

          <List sx={{padding: '0'}}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => onExpand('diet')}>
                <ListItemIcon>
                  <FoodBankIcon />
                </ListItemIcon>
                <ListItemText>Diet</ListItemText>
                <ExpandMore />
              </ListItemButton>
            </ListItem>
            <Collapse in={activeMenuItem === 'diet'} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>
                    <Link to="/pet-diet/food">Pet Food</Link>
                  </ListItemText>
                </ListItem>

                <ListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>
                    <Link to="/pet-diet/food-brands">Food Brands</Link>
                  </ListItemText>
                </ListItem>

                <ListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>
                    <Link to="/pet-diet/food-type">Food Type</Link>
                  </ListItemText>
                </ListItem>

                <ListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>
                    <Link to="/pet-diet/treats">Treats</Link>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon></ListItemIcon>
                  <ListItemText>
                    <Link to="/pet-diet/flavours">Flavour</Link>
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>
          </List>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <QueryStatsIcon />
              </ListItemIcon>
              <ListItemText>
                <Link to="/lost-pet">Lost Pets</Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <SellIcon />
              </ListItemIcon>
              <ListItemText>
                <Link to="/post-tags">Post Tags</Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>

        <Divider />
        <List>
          <ListSubheader sx={{fontWeight: 'bold', color: 'black', fontSize: '14px', textAlign: 'left'}}>
            Content Management
          </ListSubheader>

          <List sx={{padding: '0'}}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText>
                  <Link to="/moderation">Moderation Report</Link>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </List>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <NightShelterIcon />
              </ListItemIcon>
              <ListItemText>
                <Link to="/shelters">Animal Shelters</Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AodIcon />
              </ListItemIcon>
              <ListItemText>
                <Link to="/intro-slides">Intro Slides</Link>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText>
              <Link to="/settings">Settings</Link>
            </ListItemText>
          </ListItem>
        </List>
        <Divider />
        <List sx={{padding: '0'}}>
          <ListItem disablePadding>
            <ListItemButton onClick={() => onExpand('store')}>
              <ListItemIcon>
                <Store />
              </ListItemIcon>
              <ListItemText>Store </ListItemText>
              <ExpandMore />
            </ListItemButton>
          </ListItem>
          <Collapse in={activeMenuItem === 'store'} timeout="auto" unmountOnExit>
            <List component="div">
              <ListItem>
                <ListItemIcon>
                  <SpaceDashboard />
                </ListItemIcon>
                <ListItemText>
                  <Link to="/store/dashboard"> Dashboard </Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Inventory />
                </ListItemIcon>
                <ListItemText>
                  <Link to="/store/products">Products</Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <BrandingWatermark />
                </ListItemIcon>
                <ListItemText>
                  <Link to="/store/brands">Brands</Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ShoppingBasket />
                </ListItemIcon>
                <ListItemText>
                  <Link to="/store/orders">Orders</Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LanIcon />
                </ListItemIcon>
                <ListItemText>
                  <Link to="/store/categories">category</Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StorefrontIcon />
                </ListItemIcon>
                <ListItemText>
                  <Link to="/store/shops">Shops</Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <AssignmentIndIcon />
                </ListItemIcon>
                <ListItemText>
                  <Link to="/store/vendor-requests">Vendor Requests</Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ViewCarouselIcon />
                </ListItemIcon>
                <ListItemText>
                  <Link to="/store/banners">Banners</Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ReviewsIcon />
                </ListItemIcon>
                <ListItemText>
                  <Link to="/store/review">Review</Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <GradingIcon />
                </ListItemIcon>
                <ListItemText>
                  <Link to="/store/enroll">Enroll</Link>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText>
                  <Link to="/store/settings">Settings</Link>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>
        </List>

        <Divider />

        <Box pt={2}>
          <Box width="100%" textAlign="center">
            <Typography variant="p" component="p" sx={{fontSize: '13px'}}>
              <a href={process.env.REACT_APP_PRIVACY_URL} target="_blank" rel="noreferrer">
                Terms of Services
              </a>
            </Typography>
          </Box>
          <Box width="100%" textAlign="center">
            <Typography variant="p" component="p" sx={{fontSize: '13px'}}>
              <a href={process.env.REACT_APP_PRIVACY_URL} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </Typography>
          </Box>
        </Box>
      </Drawer>
    </>
  )
}

export default Sidebar
