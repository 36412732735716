import {useEffect} from 'react'
import {TableBody, TableContainer, TableHead, Paper, Typography, IconButton} from '@mui/material'
import {Delete, Edit} from '@mui/icons-material'
import {StyledTable, StyledTableRow, StyledTableHeadCell, StyledTableCell} from 'components/StyledComponent'

export const DataTable = props => {
  const {list, loading, Loader, Paginator, onEdit, onDelete, isRefreshTable, setRefreshTable, refreshTable} =
    props

  useEffect(() => {
    if (isRefreshTable) {
      refreshTable()
      setRefreshTable(false)
    }
  }, [isRefreshTable])

  return (
    <div>
      <Paper sx={{width: '100%', maxHeight: '800px', overflow: 'auto'}}>
        <TableContainer>
          <StyledTable stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableHeadCell>Name</StyledTableHeadCell>
                <StyledTableHeadCell>Parent Tag</StyledTableHeadCell>
                <StyledTableHeadCell></StyledTableHeadCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {list.length > 0 ? (
                list.map((row, index) => (
                  <StyledTableRow key={index} odd={index % 2 !== 0 ? 1 : 0}>
                    <StyledTableCell data-label="Name">{row?.name ? row.name : '-'}</StyledTableCell>
                    <StyledTableCell data-label="Type">
                      {row?.parent?.name ? row.parent?.name : '-'}
                    </StyledTableCell>
                    <StyledTableCell size="small" align="right">
                      <IconButton onClick={() => onEdit(row._id)}>
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton onClick={() => onDelete(row._id)}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : loading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={5} align="center">
                    <Loader />
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={5} align="center">
                    <Typography>No Data</Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <Paginator />
      </Paper>
    </div>
  )
}
