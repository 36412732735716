import { CustomSwitch } from "./components/CustomSwitch";
import { findIndex } from "lodash";


const MobileAppPanel = (props) => {
  const {list, updated, setUpdated} = props
  const group = 'mobile'
  
  const onChange = (data) => {
    const index = findIndex(updated, (item) => item.key === data?.key)
    setUpdated((prev) => {
      if (index === -1) {
        return [...prev, {...data, group}]
      }

      prev.splice(index, 1, {...data, group})
      return [...prev]
    })
  }

  return (
     <div className=" w-full md:w-2/3 flex flex-col m-2">
        <div className="w-full flex flex-col items-start">
          <CustomSwitch 
            list={list}
            onChange={onChange}
            name="FUND_NOW_ENABLE"
            className="w-full md:w-1/2 flex items-center "
            label="Fund Now"
          />
          <p 
            style={{
              fontSize: '10px',
              color: '#878282',
              marginTop: '-4px',
              fontStyle: 'italic'
            }}
          >
             &#9432; Enable/Disable Fund now in lost pet flow
          </p>
          <div className="w-full md:w-1/2 md:ml-2">
            
          </div>
        </div>
        
      </div>    
  );
}

export default MobileAppPanel