import {Box, Button, Checkbox, FormControlLabel, Modal, TextField} from '@mui/material'
import {connectService} from 'components/service/connectService'
import {useEffect, useMemo, useState} from 'react'
import {AutoCompleteSearch} from '../components/AutoCompleteSearch'
import {ModalLayout} from 'components/layouts/ModalLayout'
import {ImageUploader} from 'components/ImageUploader'
import * as yup from 'yup'
import {useFormik} from 'formik'
import {isEmpty, keys, pick} from 'lodash'
import {NumberTextField} from 'components/NumberTextField'
import {IconUploader} from 'components/IconUploader'

const validationSchema = yup.object({
  name: yup.string().required('Name is required')
})

const initialValues = {
  name: '',
  parent: ''
}

export const AddEditModal = connectService(props => {
  const {open, handleModal, id, data, setData, setOpen, loading, Loader, patch, create} = props

  const initialData = useMemo(() => {
    return pick(data, keys(initialValues))
  }, [data])

  const formik = useFormik({
    initialValues: id ? {...initialData} : initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      console.log('sumbit')
      const payload = {...values}
      id ? patch(id, payload) : create(payload)
    }
  })

  const onTypeChange = id => {
    formik.setFieldValue('parent', id)
  }

  const onClose = () => {
    formik.resetForm()
    setData({})
    setOpen(false)
  }

  useEffect(() => {
    if (!id) {
      setData({}) // create old persisted data
    }
  }, [id])

  return (
    <Modal
      open={open}
      onClose={() => {
        formik.resetForm()
        handleModal()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalLayout
        title={`${id ? 'Edit' : 'Add'} Post Tag`}
        onClose={onClose}
        LoaderComponent={Loader}
        loading={loading}
      >
        <form onSubmit={formik.handleSubmit}>
          <TextField
            id="standard-basic"
            label="Name"
            variant="outlined"
            {...formik.getFieldProps('name')}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
          />
          <div className="flex !my-4">
            <AutoCompleteSearch id={data?.parent} className="!w-full" onChange={onTypeChange} />
          </div>
          <Box>
            <Button variant="contained" color="primary" fullWidth size="large" type="submit">
              {id ? 'Edit' : 'Submit'}
            </Button>
          </Box>
        </form>
      </ModalLayout>
    </Modal>
  )
}, 'post-tags')
