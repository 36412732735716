import { Switch, Typography } from "@mui/material"
import { find } from "lodash"
import { useEffect, useState } from "react"

export const CustomSwitch = ({className = '', label = '', name, list, onChange}) => {
  const [value, setValue] = useState(false)
  const [data, setData] = useState(null)
  
  useEffect(() => {
    if (list?.length) {
      const _data = find(list, (item) => item.key === name)
      setData(_data)
    }
  }, [list])

  useEffect(() => {
    if (data) {
      setValue(data?.value)
    }
  }, [data])

  const handleChange = (e) => {
    setValue(e.target?.checked)
    onChange(data ? {_id: data?._id, value: e.target?.checked, key: name} : {value: e.target?.checked, key: name})
  }

  return (
    <div className={className}>
      <Typography>{label}</Typography>
      <Switch 
        name={name}
        onChange={handleChange}
        checked={value}
      />
    </div>
  )
}