import React, {useEffect, useState} from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import {connectService} from 'components/service/connectService'
export const AutoCompleteSearch = connectService(
  props => {
    const {list, find, onChange, data, setData, style, className = '!w-full'} = props
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)

    const handleInputChange = async event => {
      const searchTerm = event.target.value
      setLoading(true)
      const query = {
        $limit: 10,
        $skip: 0
      }

      if (searchTerm.length >= 3) {
        query['name'] = {}
        query['name']['$regex'] = searchTerm
        query['name']['$options'] = 'i'
        await find(query)
      } else if (searchTerm.length === 0) {
        await find(query)
      }
      setLoading(false)
    }

    useEffect(() => {
      if (list && list.length > 0) {
        setOptions(list)
      }
    }, [list])

    useEffect(() => {
      onChange(data?._id)
    }, [data])

    return (
      <Autocomplete
        id="autocomplete"
        className={className}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={option => option.name}
        options={options}
        loading={loading}
        value={data?._id ? data : null}
        onChange={(e, value) => setData(value)}
        renderInput={params => (
          <TextField
            {...params}
            label="Search by type"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
            onChange={handleInputChange}
            error={props.error}
            helperText={props.helperText}
          />
        )}
      />
    )
  },
  'post-tags',
  {$populate: ['parent']}
)
